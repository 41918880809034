<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.invite_code" placeholder="邀请码"></el-input>
          </el-col>
          <el-col :span="12">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
              <el-button @click="handsRegisterBox = true" type="primary">注册用户</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column  prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
        <el-table-column  prop="avatar" label="头像">
          <template #default="s">
            <img v-if="s.row.avatar" :src="s.row.avatar | tomedia" style="width: 2rem;height: 2rem;border-radius: .1rem" alt="">
          </template>
        </el-table-column>
        <el-table-column  prop="phone" label="手机号"></el-table-column>
        <el-table-column  prop="invite_code" label="邀请码"></el-table-column>
        <el-table-column  prop="from_invite_code" label="注册使用邀请码"></el-table-column>
        <el-table-column  prop="from_uid" label="注册邀请人">
          <template #default="s">
            <div v-if="s.row.from_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.from_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.from_user.nickname }}</span></div>
            </div>
<!--            <y_member_invite_change @reload="load" :uid="s.row.id"> <el-button type="text">手动指定</el-button> </y_member_invite_change>-->
          </template>
        </el-table-column>
        <el-table-column  prop="level_label" label="等级"></el-table-column>
        <el-table-column width="100" label="操作">
          <template #default="s">
            <div class="flex-def flex-zBetween" style="width: 100%">
              <y_member_cash_account :uid="s.row.id"></y_member_cash_account>
<!--              <y_member_up_level :uid="s.row.id"></y_member_up_level>-->
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
      <el-dialog title="注册用户" width="20rem" :visible.sync="handsRegisterBox" @closed="handsRegisterClosed">
        <el-form v-loading="registerLoading" :model="handsRegisterForm">
          <el-form-item label="邀请码">
            <el-input v-model="handsRegisterForm.invite_code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="handsRegisterForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="用户等级">
            <el-select v-model="handsRegisterForm.level" placeholder="请选择">
              <el-option
                  v-for="item in showLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登陆密码">
            <el-input v-model="handsRegisterForm.password" autocomplete="off"></el-input>
            <span class="y-desc">为空时默认填充为 123456</span>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model="handsRegisterForm.nickname" autocomplete="off"></el-input>
            <span class="y-desc">为空时默认填充为 用户昵称</span>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handsRegisterBox = false">取 消</el-button>
          <el-button type="primary" @click="handsRegister">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

// import y_member_up_level from "@/components/y_member_up_level";
// import y_member_invite_change from "@/components/y_member_invite_change";
import y_member_cash_account from "@/components/y_member_cash_account";

export default {
  name: "manage",
  components: {
    // y_member_up_level,
    // y_member_invite_change,
    y_member_cash_account
  },

  data() {
    return {
      registerLoading:false,
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
        invite_code:"",
      },
      handsRegisterBox:false,
      handsRegisterForm:{
        phone :"",
        password :"",
        nickname :"",
        invite_code:"",
        level:0,
      },
      level_area_agent_options:[
        {
          label:"普通用户",
          value:0,
        },
        {
          label:"会员",
          value:1,
        },
        {
          label:"乡镇代理",
          value:2,
        },
        {
          label:"区县代理",
          value:3,
        },
        {
          label:"市级代理",
          value:4,
        },
        {
          label:"省级代理",
          value:5,
        },
        {
          label:"合伙人",
          value:6,
        },
      ],
      level_chain_options:[
        {
          label:"会员",
          value:0,
        },
        {
          label:"代理",
          value:1,
        },
        {
          label:"老板",
          value:2,
        },
        {
          label:"总监",
          value:3,
        }
      ],
    }
  },
  computed:{
    showLevelOptions(){
      let addons = this.$store.state.sys.global.addons;
      if (addons.chain)return this.level_chain_options;
      if (addons.area_agent)return this.level_area_agent_options;
      return[]
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    handsRegisterClosed(){
      this.handsRegisterForm = {
        phone :"",
        password :"",
        nickname :"",
        invite_code:"",
        level:0,
      }
    },
    handsRegister(){
      if(this.registerLoading)return;
      this.registerLoading = true;
      this.$api.member.account.handsRegister(this.handsRegisterForm).then(()=>{
        this.handsRegisterBox = false;
        this.$message.success("操作成功");
        this.$nextTick(()=>{
          this.registerLoading = false;
        })
        this.searchGo();
      }).catch(()=>{
        this.registerLoading = false;
      })
    },
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
        invite_code:"",
      }
      this.searchGo()
    },
    load() {
      this.$api.member.account.search({
        page:this.page,
        nickname:this.searchForm.nickname,
        phone:this.searchForm.phone,
        invite_code:this.searchForm.invite_code,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>