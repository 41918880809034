<template>
  <div>
    <el-button @click="load" type="text">提现账户</el-button>
    <el-dialog custom-class="custom" append-to-body title="提现账户" :show-close="false" :visible.sync="dialogVisible"
               width="50rem">
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="type" :formatter="e=>elTypeAppend(e)" label="账户类型"></el-table-column>
        <el-table-column prop="bank_name" label="机构"></el-table-column>
        <el-table-column width="400" prop="account" label="账户"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "y_member_cash_account",
  props: {
    uid: {
      type: Number,
      default: 0
    },
  },
  computed: {},
  data() {
    return {
      list: [],
      dialogVisible: false,
      loading: false,
    }
  },
  mounted() {

  },
  methods: {
    elTypeAppend(e) {
      let str = "";
      switch (parseInt(e.type)) {
        case 0:
          str = "微信";
          break;
        case 1:
          str = "支付宝";
          break;
        case 2:
          str = "银行";
          break
      }
      return str;
    },
    load() {
      this.dialogVisible = true;
      this.loading = true;
      this.$api.member.cash.accountsByUid({uid: this.uid}).then(res => {
        this.list = res;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>